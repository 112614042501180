import service from "../util/link"
import url from "../util/apiurl"

/**
 * 公司管理请求
 */
export function company() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.company,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function excompany(obj) {
    return new Promise((resolve, reject) => {
        console.log(obj)
        service.request({
            url: url.company,
            method: "put",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function delcompany(obj) {
    return new Promise((resolve, reject) => {
        console.log(obj)
        service.request({
            url: url.company,
            method: "delete",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function addcompany(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.company,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function pages(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.company,
            method: "get",
            params: {
                page: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}