<template>
  <div class="companytable">
    <div class="search" v-if='username!=="交控创新中心"'>
      <el-button
        style="backgroundcolor: #0076f6"
        type="primary"
        @click="addUser('user')"
        >添加账号</el-button
      >
      <el-button
        style="backgroundcolor: #0076f6;margin-left:30px"
        type="primary"
        v-if="userRoleId === '1'"
        @click="addUser('agency')"
        >添加经销商</el-button
      >
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        align="center"
        stripe
        style="margin-top: 10px; color: #161718"
        max-height="640"
      >
        <el-table-column
          prop="company"
          label="公司账号"
          align="center"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="role"
          label="权限"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="contract"
          label="联系人"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center">
        </el-table-column>
        <el-table-column
          prop="address"
          label="公司地址"
          align="center"
          width="400"
        >
        </el-table-column>
        <el-table-column prop="time" label="录入时间" align="center">
        </el-table-column>
        <el-table-column v-if='username!=="交控创新中心"'  label="操作" align="center" fixed="right" width="200" >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row)"
              style="margin-right: 14px; backgroundcolor: #ebeff5"
              >编辑</el-button
            >
            <el-popconfirm
              confirmButtonText="好的"
              cancelButtonText="不用了"
              icon="el-icon-info"
              iconColor="red"
              title="确认删除该账号吗？"
              @onConfirm="handleDelete(scope.row)"
            >
              <el-button size="mini" type="danger" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
     <!--添加公司账号form表单 -->
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogFormVisible"
      width="25%"
      @close="closedialog"
    >
     
      <el-form
        ref="addFormRef"
        size="medium"
        class="center"
        style="top: 55%"
        :model="addForm"
        :rules="rules"
      >
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account">
          <el-input
            v-model="addForm.account"
            autocomplete="off"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth" prop="addpwd1">
          <el-input
            v-model="addForm.addpwd1"
            autocomplete="off"
            placeholder="请输入密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :label-width="formLabelWidth"
          prop="addpwd2"
        >
          <el-input
            v-model="addForm.addpwd2"
            autocomplete="off"
            placeholder="请确认密码"
            type="password"
            @input="addpwchange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" :label-width="formLabelWidth" prop="name">
          <el-input
            v-model="addForm.name"
            autocomplete="off"
            placeholder="联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="addForm.phone"
            autocomplete="off"
            placeholder="联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司地址"
          :label-width="formLabelWidth"
          prop="address"
        >
          <el-input
            v-model="addForm.address"
            autocomplete="off"
            placeholder="公司地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="经销商"
          :label-width="formLabelWidth"
          v-if="role_code !=='agency'"
          prop="agency"
        >
        <el-select v-model="addForm.agency" placeholder="请选择经销商">
          <el-option v-for="(item,key) in agencyArr" :label="item.name" :value="item.id" :key="key"></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="backgroundcolor: #0076f6" @click="resetaddForm()"
          >重置</el-button
        >
        <el-button
          style="backgroundcolor: #0076f6"
          type="primary"
          @click="submitaddForm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      @closed="handleClose" 
      title="编辑账号信息"
      :visible.sync="editDialogVisible"
      width="30%"
      center
    >
      <!-- 编辑账号的form表单 -->
      <el-form ref="editFormRef" :model="editForm" :rules="rules">
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account">
          <el-input
            v-model="editForm.account"
            autocomplete="off"
            placeholder="请输入账号名称"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          :label-width="formLabelWidth"
          prop="editpwd1"
        >
          <el-input
            v-model="editForm.editpwd1"
            autocomplete="off"
            placeholder="请输入密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :label-width="formLabelWidth"
          prop="editpwd2"
        >
          <el-input
            v-model="editForm.editpwd2"
            autocomplete="off"
            placeholder="请确认密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" :label-width="formLabelWidth" prop="name">
          <el-input
            v-model="editForm.name"
            autocomplete="off"
            placeholder="联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="editForm.phone"
            autocomplete="off"
            placeholder="联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司地址"
          :label-width="formLabelWidth"
          prop="address"
        >
          <el-input
            v-model="editForm.address"
            autocomplete="off"
            placeholder="公司地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="经销商"
          :label-width="formLabelWidth"
          v-if="role_code !=='agency'"
        >
         <el-select v-model="editForm.agencyid" placeholder="请选择经销商">
          <el-option v-for="(item,key) in agencyArr" :label="item.name" :value="item.id" :key="key"></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="backgroundcolor: #0076f6"
          @click="reseteditform('editFormRef')"
          >重置</el-button
        >
        <el-button
          style="backgroundcolor: #0076f6"
          type="primary"
          @click="submiteditForm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  company,
  addcompany,
  excompany,
  delcompany,
  pages,
} from "../../api/companyapi";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(value);
      console.log(this.addForm.addpwd1);
      if (value !== this.addForm.addpwd1) {
        callback(new Error("两次输入密码不一致！"));
      } else {
        callback();
      }
    };
    var validateedit = (rule, value, callback) => {
      console.log(value);
      console.log(this.editForm.pwd3);
      if (value !== this.editForm.editpwd1) {
        callback(new Error("两次输入密码不一致！"));
      } else {
        callback();
      }
    };

    return {
      agencyArr:[],
      /**账号的验证规则 */
      rules: {
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在6到20之间",
            trigger: "change",
          },
        ],
        addpwd1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 25, message: "长度在 5 到 25个字符" },
          {
            pattern: /^(\w){5,25}$/,
            message: "只能输入5-25个字母、数字、下划线",
          },
          //{ pattern:/^[a-zA-Z]w{1,4}$/, message: '以字母开头，长度在2-5之间， 只能包含字符、数字和下划线'}],
        ],
        addpwd2: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        editpwd1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 25, message: "长度在 5 到 25个字符" },
          {
            pattern: /^(\w){5,25}$/,
            message: "只能输入5-25个字母、数字、下划线",
          },
          //{ pattern:/^[a-zA-Z]w{1,4}$/, message: '以字母开头，长度在2-5之间， 只能包含字符、数字和下划线'}],
        ],
        editpwd2: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur",
          },
          {
            validator: validateedit,
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 7, message: "长度在 2 到 7 个字符" },
          { pattern: /^[\u4E00-\u9FA5]+$/, message: "用户名只能为中文" },
        ],

        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        //pattern: /^1[34578]\d{9}$/, message: '目前只支持中国大陆的手机号码' }
        address: [
          {
            required: true,
            message: "地址不能为空",
            trigger: "blur",
          },
        ],
      },
      username:'',
      tableData: [],
      /**分页 */
      currentPage3: 1,
      pagesize: 12,
      total: 32,
      dialogFormVisible: false,
      innerVisible: false,
      textdialog: "",
      addForm: {
        account: "",
        addpwd1: "",
        pwd3: "",
        name: null,
        phone: null,
        address: null,
        agency:null
      },
      editDialogVisible: false,
      selectDisabled: false,
      editForm: {
        id: null,
        account: null,
        editpwd1: null,
        editpwd2: null,
        name: null,
        phone: null,
        address: null,
        agencyid:null
      },
      formLabelWidth: "80px",
      dialogName:'添加账号',
      role_code:'',
    };
  },
  mounted() {
    this.username=this.$cookies.get("user")
    this.getcompany();
  },
  methods: {
    //添加用户或者经销商
    addUser(role){
      this.dialogFormVisible = true;
      if(role == 'user'){
        this.dialogName = "添加账号"
        this.role_code = "common"
        if(this.userRoleId === '2'){
          this.addForm.agency = this.agencyArr[0].id
        }
      }else{
        this.dialogName = "添加经销商"
        this.role_code = "agency"
      }
    },
    addpwchange(e) {
      this.$forceUpdate();
    },
    getcompany() {
      company()
        .then((res) => {
          if (res.status == 200) {
            this.tableData = [];
            res.data.datas.forEach(item => {
              if(item.role_code=="admin"){
                item.role = '管理员'
              }else if(item.role_code=="common"){
                item.role = '普通用户'
              }else if(item.role_code=="agency"){
                item.role = '经销商'
              }
              this.tableData.push(item)
            });
            // this.tableData = res.data.datas;
            this.pagesize = res.data.page_size;
            this.total = res.data.total_pages;
            if(this.userRoleId === '1'){
              this.agencyArr = res.data.agency_array;
            }else if(this.userRoleId === '2'){
              this.agencyArr = [res.data.agency_array.find(item=>item.name==this.username)];
            }
            console.log('tableData',this.tableData)
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log("000000");
      pages(val)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = [];
            res.data.datas.forEach(item => {
              if(item.role_code=="admin"){
                item.role = '管理员'
              }else if(item.role_code=="common"){
                item.role = '普通用户'
              }else if(item.role_code=="agency"){
                item.role = '经销商'
              }
              this.tableData.push(item)
            });
            // this.tableData = res.data.datas;
          }
        })
        .catch(() => {
          this.$message.error(e.message);
        });
      console.log(`当前页: ${val}`);
    },
    //点击编辑
    handleEdit(row) {
      console.log(row)
      this.editForm.id = row.id;
      this.editForm.account = row.company;
      this.editForm.editpwd1 = row.password;
      this.editForm.name = row.contract;
      this.editForm.phone = row.phone;
      this.editForm.address = row.address;
      if(row.agencyid == null){
        this.editForm.agencyid = 0
      }else{
        this.editForm.agencyid = row.agencyid;
      }
      this.editDialogVisible = true;
    },
    handleDelete(row) {
      var param = new URLSearchParams();
      param.append("id", row.id);
      delcompany(param).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message:
              "删除账号 " +
              row.company +
              " 成功,已将此公司下属设备归至易合交通",
            type: "success",
          });
          this.getcompany();
        } else {
          this.$message.error("账号修改失败");
          this.getcompany();
        }
      });
    },
    //修改账号
    submiteditForm() {
      this.$refs.editFormRef.validate((valid) => {
        if (valid) {
          var param = new URLSearchParams();
          param.append("id", this.editForm.id);
          param.append("company", this.editForm.account);
          param.append("password", this.editForm.editpwd2);
          console.log(this.editForm.pwd2);
          param.append("contract", this.editForm.name);
          param.append("phone", this.editForm.phone);
          param.append("address", this.editForm.address);
          param.append("agencyid", this.editForm.agencyid);
          param.append("agencyName", this.agencyArr.find(item => item.id ==this.editForm.agencyid).name);
          excompany(param).then((res) => {
            if (res.status === 200) {
              this.$message.success(
                "修改账号 " + this.editForm.account + " 成功"
              );
              this.editDialogVisible = false;
              this.getcompany();
            } else {
              this.$message.error("账号修改失败");
              this.editDialogVisible = false;
              this.getcompany();
            }
          });
        }
      });
    },
    handleClose() {
      this.$refs.editFormRef.resetFields();
    },
    resetaddForm() {
      this.$refs.addFormRef.resetFields();
    },
    reseteditform(editFormRef) {
      this.$refs[editFormRef].resetFields();
    },
    // outcancel() {
    //   this.addForm.account = "";
    //   this.addForm.pwd1 = "";
    //   this.addForm.addpwd2 = "";
    //   this.dialogFormVisible = false;
    // },
    closedialog() {
      this.addForm.account = "";
      this.addForm.pwd1 = "";
      this.addForm.addpwd2 = "";
      this.dialogFormVisible = false;
    },
    closeinner() {
      if (
        this.textdialog == "账号密码不能为空" ||
        this.textdialog == "俩次输入密码不一致"
      ) {
        this.innerVisible = false;
      } else {
        this.addForm.account = "";
        this.addForm.pwd1 = "";
        this.addForm.addpwd2 = "";
        this.innerVisible = false;
        this.dialogFormVisible = false;
      }
    },
    innerconfirm() {
      if (
        this.textdialog == "账号密码不能为空" ||
        this.textdialog == "俩次输入密码不一致"
      ) {
        this.innerVisible = false;
      } else {
        this.addForm.account = "";
        this.addForm.pwd1 = "";
        this.addForm.addpwd2 = "";
        this.innerVisible = false;
        this.dialogFormVisible = false;
      }
    },
    submitaddForm() {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var param = new URLSearchParams();
          param.append("company", this.addForm.account);
          param.append("password", this.addForm.addpwd2);
          param.append("contract", this.addForm.name);
          param.append("phone", this.addForm.phone);
          param.append("address", this.addForm.address);
          param.append('role_code',this.role_code)
          if(this.addForm.agency == null){
            param.append('agencyid',0)
          }else{
            param.append('agencyid',this.addForm.agency)
          }
          addcompany(param)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.innerVisible = true;
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                this.resetaddForm();
                this.dialogFormVisible = false;
                this.getcompany();
              } else if (res.status == 5026) {
                this.$message({
                  showClose: true,
                  message: "添加失败，公司名称重复请重新添加",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log("error submit!!");
          this.$message({
            type: "warning",
            message: "请重新填写信息",
          });
          return false;
        }
      });
    },
    innerconfirm() {
      if (
        this.textdialog == "账号密码不能为空" ||
        this.textdialog == "俩次输入密码不一致"
      ) {
        this.innerVisible = false;
      } else {
        this.addForm.account = "";
        this.addForm.pwd1 = "";
        this.addForm.addpwd2 = "";
        this.innerVisible = false;
        this.dialogFormVisible = false;
      }
    },
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1920px) {
  .companytable {
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
  .paging {
    width: 34%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }

  .search {
    font-size: 0.875rem;
    text-align: left;
    /* border: 0.0625rem solid black; */
    /* background-color: #00a2e9 ; */

    box-sizing: border-box;
  }
}
@media only screen and (max-width: 1080px) {
  .paging {
    width: 66%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
}
</style>